import localforage from 'localforage';
import { autorun, observable, toJS } from 'mobx';

import { BlockTime } from 'src/types';

export interface BlockTimeObject {
  recurring: BlockTime[];
  single: BlockTime[];
}
export interface BlockTimesStateProps {
  blockTimes: BlockTimeObject;
  initialized: boolean;
}

export const initialBlockTimesState: BlockTimesStateProps = {
  blockTimes: { recurring: [], single: [] },
  initialized: false,
};

const BlockTimesState = observable<BlockTimesStateProps>(
  initialBlockTimesState,
);

autorun(() => {
  // ! Note: 'initialized' is set to 'false' after hot module reload in development environment.
  if (!BlockTimesState.initialized) {
    return;
  }

  // Converts the observable-object to a javascript-object as mobx-observables can not be stored.
  const blockTimesState = toJS(BlockTimesState);

  localforage
    .setItem('blockTimesState', blockTimesState)
    .catch((error: any) => console.warn('could not be stored locally ', error));
});

export default BlockTimesState;
