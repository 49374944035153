export const TestIds = {
  layouts: {
    defaultLayout: {
      wrapper: 'layouts-defaultLayout-wrapper',
    },
    stackLayout: {
      wrapper: 'layouts-defaultLayout-stackLayout',
    },
  },
  views: {
    appointmentCreate: {
      pageWrapper: 'views-appointmentCreate-pageWrapper',
    },
    appointmentEdit: {
      pageWrapper: 'views-appointmentEdit-pageWrapper',
    },
    appointmentRecord: {
      pageWrapper: 'views-appointmentRecord-pageWrapper',
      editIcon: 'views-appointmentRecord-editIcon',
    },
    blocktTimes: {
      pageWrapper: 'views-blockTimes-pageWrapper',
    },
    forgotPassword: {
      successMessage: 'views-forgotPassword-successMessage',
    },
    home: {
      pageWrapper: 'views-home-pageWrapper',
      appointmentCreateButton: 'views-home-appointmentCreateButton',
    },
    appointmentList: {
      pageWrapper: 'views-appointmentList-pageWrapper',
    },
    login: {
      pageWrapper: 'views-login-pageWrapper',
    },
    monthlySummary: {
      pageWrapper: 'views-monthlySummary-pageWrapper',
      slider: 'views-monthlySummary-slider',
      slide: 'views-monthlySummary-slide',
    },
    notFound: {
      pageWrapper: 'views-notFound-pageWrapper',
    },
    profile: {
      pageWrapper: 'views-profile-pageWrapper',
    },
    resetPassword: {
      invalidLinkMessage: 'views-resetPassword-invalidLinkMessage',
      errorMessage: 'views-resetPassword-errorMessage',
      successMessage: 'views-resetPassword-successMessage',
    },
  },
  components: {
    appointmentCard: {
      actionIcon: 'components-appointmentCard-actionIcon',
      card: 'components-appointmentCard-card',
      deleteIcon: 'components-appointmentCard-deleteIcon',
      hasClearance: 'components-appointmentCard-hasClearance',
      hasRecord: 'components-appointmentCard-hasRecord',
      delayed: 'components-appointmentCard-delayed',
      uploadPending: 'components-appointmentCard-uploadPending',
    },
    appointmentForm: {
      customerIdField: 'components-appointmentForm-customerIdField',
      customerIdList: 'components-appointmentForm-customerIdList',
      dateDayField: 'components-appointmentForm-dateDayField',
      endTimeField: 'components-appointmentForm-endTimeField',
      startTimeField: 'components-appointmentForm-startTimeField',
      recurrenceTypeToggle: 'components-appointmentForm-recurrenceTypeToggle',
      recurrenceTypeWorkdays:
        'components-appointmentForm-recurrenceTypeWorkdays',
      recurrenceTypeWeekly: 'components-appointmentForm-recurrenceTypeWeekly',
      recurrenceTypeBiWeekly:
        'components-appointmentForm-recurrenceTypeBiWeekly',
      recurrenceTypeMonthly: 'components-appointmentForm-recurrenceTypeMonthly',
      recurrenceEndDayField: 'components-appointmentForm-recurrenceEndDayField',
      submitError: 'components-appointmentForm-submitError',
      submitButton: 'components-appointmentForm-submitButton',
    },
    appointmentRecordForm: {
      dateDayField: 'components-appointmentRecordForm-dateDayField',
      travelDistanceField:
        'components-appointmentRecordForm-travelDistanceField',
      signatureDoaField: 'components-appointmentRecordForm-signatureDoaField',
      signatureDoppField: 'components-appointmentRecordForm-signatureDoppField',
      signatureCustomerField:
        'components-appointmentRecordForm-signatureCustomerField',
      signatureEmployeeField:
        'components-appointmentRecordForm-signatureEmployeeField',
      wasFirstInterviewSwitch:
        'components-appointmentRecordForm-wasFirstInterviewSwitch',
      submitError: 'components-appointmentRecordForm-submitError',
      submitButton: 'components-appointmentRecordForm-submitButton',
      infoText: 'components-appointmentRecordForm-infoText',
    },
    blockTimes: {
      allDaySwitch: 'components-blockTimes-allDaySwitch',
      card: 'components-blockTimes-card',
      endTime: 'components-blockTimes-endTime',
      date: 'components-blocktTimes-date',
      startTime: 'components-blockTimes-startTime',
      newTab: 'components-blocktTimes-newTab',
      overviewTab: 'components-blocktTimes-overviewTab',
      recurrenceSwitch: 'components-blockTimes-recurrenceSwitch',
      submitBtn: 'components-blocktTimes-submitBtn',
    },
    bottomNav: {
      wrapper: 'components-bottomNav-wrapper',
      profile: 'components-bottomNav-goToProfile',
    },
    confirmDialog: {
      dialogWrapper: 'components-confirmDialog-dialogWrapper',
      denyButton: 'components-confirmDialog-denyButton',
      confirmButton: 'components-confirmDialog-confirmButton',
    },
    dailyAgenda: {
      currentTimeDivider: 'components-dailyAgenda-currentTimeDivider',
      timeSteps: 'components-dailyAgenda-timeSteps',
      wrapper: 'components-dailyAgenda-wrapper',
    },
    endTimeField: 'components-appointmentRecordForm-endTimeField',
    timeIntervalSelectionSlider: {
      selectionTab: 'components-timeIntervalSelectionSlider-selectionTab',
      selectionTabs: 'components-timeIntervalSelectionSlider-selectionTab',
    },
    forgotPasswordForm: {
      emailField: 'components-forgotPasswordForm-emailField',
      submitError: 'components-forgotPasswordForm-submitError',
      submitButton: 'components-forgotPasswordForm-submitButton',
    },
    hourSelect: 'components-hourSelect',
    hourSelectError: 'components-hourSelectError',
    loginForm: {
      identityField: 'components-loginForm-identityField',
      passwordField: 'components-loginForm-passwordField',
      submitError: 'components-loginForm-submitError',
      loginButton: 'components-loginForm-loginButton',
      forgotPasswordLink: 'components-loginForm-forgotPasswordLink',
    },
    needsAssessmentForm: {
      budgetEl: 'components-needsAssessmentForm-budgetEl',
      budgetVp: 'components-needsAssessmentForm-budgetVp',
      closeIcon: 'components-needsAssessmentForm-closeIcon',
      EL_VP: 'components-needsAssessmentForm-EL_VP',
      form: 'components-needsAssessmentForm',
      malteserModal: 'components-needsAssessmentForm-malteserModal',
      deploymentPlanning: {
        careAllowance:
          'components-needsAssessmentForm-deploymentPlanning-careAllowance',
        dispositionHoursBiWeekly:
          'components-needsAssessmentForm-deploymentPlanning-dispositionHoursBiWeekly',
        dispositionHoursWeekly:
          'components-needsAssessmentForm-deploymentPlanning-dispositionHoursWeekly',
        dispositionSelection:
          'components-needsAssessmentForm-deploymentPlanning-dispositionSelection',
        privateAccount:
          'components-needsAssessmentForm-deploymentPlanning-privateAccount',
      },
      healthInsuranceBenefits:
        'components-needsAssessmentForm-healthInsuranceBenefits',
      receivingCareAllowance: {
        careService:
          'components-needsAssessmentForm-receivingCareAllowance-careService',
        qualityAssuranceInterview:
          'components-receivingCareAllowance-qualityInsuranceInterview',
        qualityAssurancePerson:
          'components-needsAssessmentForm-receivingCareAllowance-qualityAssurancePerson',
        qualityAssuranceSelection: {
          yes: 'components-needsAssessmentForm-receivingCareAllowance-qualityAssuranceSelection-yes',
          no: 'components-needsAssessmentForm-receivingCareAllowance-qualityAssuranceSelection-no',
        },
        value: 'components-needsAssessmentForm-receivingCareAllowance-value',
        yes: 'components-needsAssessmentForm-receivingCareAllowance-yes',
        no: 'components-needsAssessmentForm-receivingCareAllowance-no',
        partially:
          'components-needsAssessmentForm-receivingCareAllowance-partially',
      },
      registeredCarer: 'components-needsAssessmentForm-registeredCarer',
      requiredServices: {
        error: 'components-needsAssessmentForm-requiredServices-error',
        normalChores:
          'components-needsAssessmentForm-requiredServices-normalChores',
        cooking: 'components-needsAssessmentForm-requiredServices-cooking',
        shopping: 'components-needsAssessmentForm-requiredServices-shopping',
        strolling: 'components-needsAssessmentForm-requiredServices-strolling',
        ironing: 'components-needsAssessmentForm-requiredServices-ironing',
        windowCleaning:
          'components-needsAssessmentForm-requiredServices-windowCleaning',
        errands: 'components-needsAssessmentForm-requiredServices-errands',
        bedLinen: 'components-needsAssessmentForm-requiredServices-bedLinen',
      },
      signatureCustomer: 'components-needsAssessmentForm-signatureCustomer',
      specifics: {
        animals: 'components-needsAssessmentForm-specifics-animals',
        others: 'components-needsAssessmentForm-specifics-others',
        smoker: 'components-needsAssessmentForm-specifics-smoker',
      },
      startDate: 'components-needsAssessmentForm-startDate',
      submitButton: 'components-needsAssessmentForm-submitButton',
      withoutVP: 'components-needsAssessmentForm-withoutVP',
      withoutVpHours: 'components-needsAssessmentForm-withoutVpHours',
      freeOfChargeAid: {
        careBox: 'components-needsAssessmentForm-freeOfChargeAid-careBx',
        default: 'components-needsAssessmentForm-freeOfChargeAid',
        homeEmergencyCall:
          'components-needsAssessmentForm-freeOfChargeAid-homeEmergencyCall',
        nursingAdvice:
          'components-needsAssessmentForm-freeOfChargeAid-nursingAdvice',
      },
      vpAlreadyBilled: 'components-needsAssessmentForm-vpAlreadyBilled',
    },
    startTimeField: 'components-appointmentRecordForm-startTimeField',
    topBar: {
      wrapper: 'components-topBar-wrapper',
      backIcon: 'components-topBar-backIcon',
      actionIcon: 'components-topBar-actionIcon',
    },
    resetPasswordForm: {
      passwordField: 'components-resetPasswordForm-passwordField',
      repeatPasswordField: 'components-resetPasswordForm-repeatPasswordField',
      submitError: 'components-resetPasswordForm-submitError',
      submitButton: 'components-resetPasswordForm-submitButton',
    },
    signature: {
      signaturePadWrapper: 'components-signature-signaturePadWrapper',
      retryButton: 'components-signature-retryButton',
      confirmButton: 'components-signature-confirmButton',
    },
    summaryAccordion: {
      accordion: 'components-summaryAccordion-accordion',
      summaryItem: 'components-summaryAccordion-summaryItem',
    },
    snackBar: {
      closeButton: 'components-snackBar-closeButton',
    },
    weeklyAgenda: {
      currentTimeDivider: 'components-weeklyAgenda-currentTimeDivider',
      timeSteps: 'components-weeklyAgenda-timeSteps',
      wrapper: 'components-weeklyAgenda-wrapper',
    },
  },
};
