import { type FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import clsx from 'clsx';
import { addMonths, format } from 'date-fns';

import { useTopBar } from 'src/contexts/TopBarContext';
import { TestIds } from 'src/testIds';
import { BlockTime } from 'src/types/blockTimes';
import BlockTimesState from 'src/services/state/BlockTimes';
import themeColors from 'src/theme/colors';
import { BlockTimeCard } from 'src/components/BlockTimeCard/BlockTimeCard';
import { BlockTimeForm } from 'src/components/BlockTimeForm/BlockTimeForm';
import Page from 'src/components/Page/Page';

import { RecurrenceType } from '../../types/appointment';

import useStyles from './BlockTimesView.styles';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

interface FormValues {
  allDay: boolean;
  dateDay: string;
  endTime: string;
  recurrenceEndDay: string;
  recurrenceIndex: number | null;
  recurrenceType: RecurrenceType;
  startTime: string;
  submit?: string;
}

const initialValues: FormValues = {
  allDay: false,
  dateDay: '',
  endTime: '',
  recurrenceEndDay: '',
  recurrenceIndex: null,
  recurrenceType: RecurrenceType.ONCE,
  startTime: '',
};

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <Box key={value} role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box pt={4}>{children}</Box>}
  </Box>
);

const BlockTimesView: FC = () => {
  const topBar = useTopBar();
  const { marginRight, tabs, tabLabel } = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [singleEntries, setSingleEntries] = useState<BlockTime[]>([]);
  const [recurringEntries, setRecurringEntries] = useState<BlockTime[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    setSingleEntries(BlockTimesState.blockTimes.single);
    setRecurringEntries(BlockTimesState.blockTimes.recurring);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BlockTimesState.blockTimes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => topBar.setTitle(t('Views.BlockTimes.title')), []);

  // Default Recurrence Endday to today + 3 months
  initialValues.recurrenceEndDay = format(
    addMonths(new Date(), 3),
    'yyyy-MM-dd',
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Page
      data-test-id={TestIds.views.blocktTimes.pageWrapper}
      title={t('Views.BlockTimes.title')}
      display="flex"
      alignContent="center"
    >
      <Tabs className={tabs} value={tabIndex} onChange={handleTabChange}>
        <Tab
          className={clsx(tabLabel, marginRight)}
          label={t('General.newEntry')}
        />
        <Tab className={tabLabel} label={t('General.overview')} />
      </Tabs>

      <TabPanel
        data-test-id={TestIds.components.blockTimes.newTab}
        value={tabIndex}
        index={0}
      >
        <BlockTimeForm
          afterSubmit={
            // 'Navigate' to blockTimes overview.
            () => setTabIndex(1)
          }
        />
      </TabPanel>

      <TabPanel
        data-test-id={TestIds.components.blockTimes.overviewTab}
        index={1}
        value={tabIndex}
      >
        <Typography variant="h6" color={themeColors.shade4} mb={1}>
          {t('Views.BlockTimes.blockTimesHint')}
        </Typography>
        <>
          <Typography variant="h3">
            {t('Views.BlockTimes.individualEntries')}
          </Typography>
          <Box mb={2}>
            {singleEntries.length > 0 ? (
              singleEntries?.map((blockTime, index) => (
                <BlockTimeCard key={`${index}`} {...blockTime} />
              ))
            ) : (
              <Typography variant="h4" color={themeColors.shade4} mt={1}>
                {t('Views.BlockTimes.noSingleEntries')}
              </Typography>
            )}
          </Box>
        </>
        <Box mt={3}>
          <Typography variant="h3">
            {t('Views.BlockTimes.serialEntries')}
          </Typography>
          <Box mb={2}>
            {recurringEntries.length > 0 ? (
              recurringEntries?.map((BlockTime, index) => (
                <BlockTimeCard key={`${index}`} {...BlockTime} />
              ))
            ) : (
              <Typography variant="h4" color={themeColors.shade4} mt={1}>
                {t('Views.BlockTimes.noRecurringEntries')}
              </Typography>
            )}
          </Box>
        </Box>
      </TabPanel>
    </Page>
  );
};

export default BlockTimesView;
