import { useTranslation } from 'react-i18next';
import BlockIcon from '@mui/icons-material/Block';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import themeColors from 'src/theme/colors';

import useStyles from './BannerNote.styles';

export const BlockTimeBannerNote = () => {
  const { blockTimeIcon, blockTimeNote, note } = useStyles();
  const { t } = useTranslation('translation', {
    keyPrefix: 'BlockTimes',
  });

  return (
    <Box className={clsx(note, blockTimeNote)}>
      <BlockIcon className={blockTimeIcon} fontSize="inherit" />
      <Typography color={themeColors.shade4} variant="h6">
        {t('allDayLabel')}
      </Typography>
    </Box>
  );
};
