/* eslint-disable eqeqeq */
import { type FC, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Add,
  DateRange as CalendarIcon,
  Edit,
  Error,
  Info,
} from '@mui/icons-material';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { TestIds } from 'src/testIds';
import { Customer } from 'src/types';
import { mapToPostData, scrollToError } from 'src/utils';
import { createNeedsAssessment } from 'src/services/api/api';
import i18n from 'src/services/i18n/i18n';
import themeColors from 'src/theme/colors';
import Button from 'src/components/Button/Button';
import Form from 'src/components/Form/Form';
import Image from 'src/components/Image/Image';
import {
  CareAllowanceOptions,
  NeedsAssessmentFormData,
} from 'src/components/NeedsAssessment/needsAssessment';
import Signature from 'src/components/Signature/Signature';
import TextField from 'src/components/TextField/TextField';

import {
  careAllowanceOptions,
  dispositionOptions,
  freeOfChargeAid,
  requiredServiceOptions,
  yesNoOptions,
} from './fieldOptions';
import { HourSelect } from './HourSelect';
import { HourSelectError } from './HourSelectError';
import { SectionTitle } from './SectionTitle';
import { Subtitle } from './Subtitle';

import useStyles from 'src/components/NeedsAssessment/NeedsAssessment.styles';

const ComponentTestIds = TestIds.components.needsAssessmentForm;

const validationSchema = Yup.object().shape({
  deploymentPlanning: Yup.object({
    careAllowance: Yup.boolean(),
    dispositionHoursBiWeekly: Yup.number()
      .required()
      .when('dispositionSelection', {
        is: 'biWeekly',
        then: Yup.number()
          .required(i18n.t('NeedsAssessmentForm.Errors.hourFieldRequired'))
          .test({
            message: i18n.t('NeedsAssessmentForm.Errors.hourFieldRequired'),
            test: (value) => (value ? value > 0 : false),
          }),
      }),
    dispositionHoursWeekly: Yup.number()
      .required()
      .when('dispositionSelection', {
        is: 'weekly',
        then: Yup.number()
          .required(i18n.t('NeedsAssessmentForm.Errors.hourFieldRequired'))
          .test({
            message: i18n.t('NeedsAssessmentForm.Errors.hourFieldRequired'),
            test: (value) => (value ? value > 0 : false),
          }),
      }),
    privateAccount: Yup.boolean(),
  }),
  healthInsuranceInfo: Yup.object({
    budgetEl: Yup.number().required(
      i18n.t('NeedsAssessmentForm.Errors.inputNumber'),
    ),
    budgetVp: Yup.number().required(
      i18n.t('NeedsAssessmentForm.Errors.inputNumber'),
    ),
  }),
  receivingCareAllowance: Yup.object({
    careService: Yup.string().when(['value', 'qualityAssuranceInterview'], {
      is: (value: CareAllowanceOptions, qualityAssuranceInterview: string) =>
        value == CareAllowanceOptions.NO ||
        value == CareAllowanceOptions.PARTIALLY ||
        (value == CareAllowanceOptions.YES &&
          qualityAssuranceInterview === 'no'),
      then: Yup.string().required(
        i18n.t('NeedsAssessmentForm.Errors.careServiceRequired'),
      ),
    }),
    qualityAssuranceInterview: Yup.string().required(),
    qualityAssurancePerson: Yup.string().when('qualityAssuranceInterview', {
      is: (value: string) => value === 'yes',
      then: Yup.string().required(
        i18n.t('NeedsAssessmentForm.Errors.qualityAssurancePersonRequired'),
      ),
    }),
    value: Yup.string().required(),
  }),
  requiredServices: Yup.object().shape({
    bedLinen: Yup.boolean().when(
      [
        'cooking',
        'errands',
        'ironing',
        'normalChores',
        'shopping',
        'strolling',
        'windowCleaning',
      ],
      {
        is: (
          cooking: boolean,
          errands: boolean,
          ironing: boolean,
          normalChores: boolean,
          shopping: boolean,
          strolling: boolean,
          windowCleaning: boolean,
        ) =>
          !cooking &&
          !errands &&
          !ironing &&
          !normalChores &&
          !shopping &&
          !strolling &&
          !windowCleaning,
        then: Yup.boolean().oneOf(
          [true],
          i18n.t('NeedsAssessmentForm.Errors.atLeastOneRequired'),
        ),
      },
    ),
    cooking: Yup.boolean(),
    errands: Yup.boolean(),
    ironing: Yup.boolean(),
    normalChores: Yup.boolean(),
    shopping: Yup.boolean(),
    strolling: Yup.boolean(),
    windowCleaning: Yup.boolean(),
  }),
  signatureCustomer: Yup.string().required(
    i18n.t('NeedsAssessmentForm.Signature.required'),
  ),
});

interface Props {
  afterSubmit: () => void;
  customer: Customer;
  orderCareBox: () => void;
}

const initialValues: NeedsAssessmentFormData = {
  deploymentPlanning: {
    careAllowance: false,
    dispositionHoursBiWeekly: 0,
    dispositionHoursWeekly: 0,
    dispositionSelection: 'weekly',
    privateAccount: false,
  },
  freeOfChargeAidRequested: {
    homeEmergencyCall: false,
    nursingAdvice: false,
    shouldOrderCareBox: false,
  },
  healthInsuranceBenefits: {
    selection: 'withoutVP',
  },
  healthInsuranceInfo: {
    budgetEl: 0,
    budgetVp: 0,
    registeredCarer: '',
    vpAlreadyBilled: false,
  },
  receivingCareAllowance: {
    careService: '',
    qualityAssuranceInterview: 'no',
    qualityAssurancePerson: '',
    value: CareAllowanceOptions.NO,
  },
  requiredServices: {
    bedLinen: false,
    cooking: false,
    errands: false,
    ironing: false,
    normalChores: false,
    shopping: false,
    strolling: false,
    windowCleaning: false,
  },
  signatureCustomer: '',
  specifics: { animals: '', others: '', smoker: 'no' },
  startDate: new Date(Date.now()),
};

export const NeedsAssessmentForm: FC<Props> = ({
  afterSubmit,
  customer,
  orderCareBox,
}) => {
  const confirm = useConfirm();
  const formikRef = useRef<FormikProps<NeedsAssessmentFormData>>(null);
  const { t } = useTranslation('translation');
  const { enqueueSnackbar } = useSnackbar();
  const {
    editIcon,
    malteserNumber,
    malteserNumberRow,
    signatureFieldLabel,
    signatureName,
    signaturePreview,
  } = useStyles();

  const [signatureOpen, setSignatureOpen] = useState(false);
  const [showInfoText, setShowInfoText] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);

  /**
   * Prepare the form`s values and handle success and error cases after submitting them.
   */
  const handleSubmit = async (
    values: NeedsAssessmentFormData,
    { setErrors }: FormikHelpers<NeedsAssessmentFormData>,
  ) => {
    try {
      // Clone values then parse them to usable post data for the request.
      const formData = { ...values };
      const postData = mapToPostData(formData);

      await createNeedsAssessment({ customerId: customer.id, data: postData });

      // Show a success snackbar message
      enqueueSnackbar(t('NeedsAssessmentForm.successMessage'), {
        variant: 'success',
      });

      // Conditionally open modal to ask them to call Malteser
      if (formData.freeOfChargeAidRequested.homeEmergencyCall) {
        const phoneNumbers = [
          {
            href: 'tel:0221126062142',
            number: '0221 12606 2142',
            title: t('NeedsAssessmentForm.MalteserModal.NRW'),
          },
          {
            href: 'tel:08009966023',
            number: '0800 996 60 23',
            title: t('NeedsAssessmentForm.MalteserModal.otherStates'),
          },
        ];

        await confirm({
          content: (
            // Must be Box instead of Fragment for Cypress to find the data-test-id
            <Box data-test-id={ComponentTestIds.malteserModal}>
              <Typography>
                {t('NeedsAssessmentForm.MalteserModal.description')}
              </Typography>
              {phoneNumbers.map(({ href, number, title }) => (
                <Fragment key={title}>
                  <Typography mt={1}>{title}</Typography>
                  <Box className={malteserNumberRow}>
                    <PhoneForwardedOutlinedIcon fontSize="small" />
                    <a className={malteserNumber} href={href}>
                      {number}
                    </a>
                  </Box>
                </Fragment>
              ))}
            </Box>
          ),
          hideCloseButton: true,
          hideSecondaryButton: true,
          onClose: { disableBackdropClick: true },
          primaryText: t('NeedsAssessmentForm.MalteserModal.confirm'),
          title: (
            <Typography fontSize="22px">
              {t('NeedsAssessmentForm.MalteserModal.title')}
            </Typography>
          ),
        }).then(
          () =>
            formData.freeOfChargeAidRequested.shouldOrderCareBox &&
            orderCareBox(),
        );
      } else {
        if (formData.freeOfChargeAidRequested.shouldOrderCareBox) {
          orderCareBox();
        }
      }

      // Execute the afterSubmit callback. If no callback was provided, this redirects to the HomeView.
      afterSubmit();
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary') return;

      if (error.response?.data) {
        const errorMessage =
          error.response.data.detail ||
          error.response?.data[0] ||
          error.response.data.nonFieldErrors?.join(' - ');

        setErrors({ submit: errorMessage });
      } else {
        setErrors({ submit: t('NeedsAssessmentForm.couldNotCreate') });
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <Form data-test-id={ComponentTestIds.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} mb={2}>
              <Typography variant="h2">
                {t('NeedsAssessmentForm.title')}
              </Typography>
            </Grid>

            <SectionTitle
              title={t('NeedsAssessmentForm.HealthInsuranceInfo.title')}
              noTopMargin
            />
            <Grid item xs={12}>
              <FormControl data-test-id={ComponentTestIds.budgetEl} fullWidth>
                <TextField
                  error={
                    !!(
                      touched.healthInsuranceInfo?.budgetEl &&
                      errors.healthInsuranceInfo?.budgetEl
                    )
                  }
                  helperText={
                    touched.healthInsuranceInfo?.budgetEl &&
                    errors.healthInsuranceInfo?.budgetEl
                  }
                  label={t('NeedsAssessmentForm.HealthInsuranceInfo.budgetEl')}
                  name="healthInsuranceInfo.budgetEl"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t('NeedsAssessmentForm.inputPlaceholder')}
                  required
                  type="number"
                  value={values.healthInsuranceInfo.budgetEl}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl data-test-id={ComponentTestIds.budgetVp} fullWidth>
                <TextField
                  error={
                    !!(
                      touched.healthInsuranceInfo?.budgetVp &&
                      errors.healthInsuranceInfo?.budgetVp
                    )
                  }
                  helperText={
                    touched.healthInsuranceInfo?.budgetVp &&
                    errors.healthInsuranceInfo?.budgetVp
                  }
                  label={t('NeedsAssessmentForm.HealthInsuranceInfo.budgetVp')}
                  name="healthInsuranceInfo.budgetVp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t('NeedsAssessmentForm.inputPlaceholder')}
                  required
                  type="number"
                  value={values.healthInsuranceInfo.budgetVp}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                data-test-id={ComponentTestIds.vpAlreadyBilled}
                name="healthInsuranceInfo.vpAlreadyBilled"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.healthInsuranceInfo.vpAlreadyBilled}
                    onChange={handleChange}
                  />
                }
                label={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {t(
                      'NeedsAssessmentForm.HealthInsuranceInfo.vpAlreadyBilled',
                    )}
                  </Box>
                }
                labelPlacement="end"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                data-test-id={ComponentTestIds.registeredCarer}
                fullWidth
              >
                <TextField
                  label={t(
                    'NeedsAssessmentForm.HealthInsuranceInfo.registeredCarer',
                  )}
                  name="healthInsuranceInfo.registeredCarer"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.healthInsuranceInfo.registeredCarer}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <SectionTitle
              title={t('NeedsAssessmentForm.ReceivingCareAllowance.title')}
            />
            <Grid item xs={12}>
              <RadioGroup
                data-test-id={ComponentTestIds.receivingCareAllowance.value}
                name="receivingCareAllowance.value"
                onChange={handleChange}
                value={values.receivingCareAllowance.value}
                defaultValue="no"
                row
              >
                {careAllowanceOptions.map(({ dataTestId, label, value }) => (
                  <FormControlLabel
                    key={label}
                    control={<Radio data-test-id={dataTestId} />}
                    label={
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {label}
                      </Box>
                    }
                    labelPlacement="end"
                    value={value}
                  />
                ))}
              </RadioGroup>
            </Grid>
            {values.receivingCareAllowance.value == CareAllowanceOptions.YES ? (
              <Grid item xs={12}>
                <FormLabel>
                  {t(
                    'NeedsAssessmentForm.ReceivingCareAllowance.qualityAssuranceInterview',
                  )}
                </FormLabel>
                <RadioGroup
                  data-test-id={
                    ComponentTestIds.receivingCareAllowance
                      .qualityAssuranceInterview
                  }
                  name="receivingCareAllowance.qualityAssuranceInterview"
                  onChange={handleChange}
                  value={
                    values.receivingCareAllowance.qualityAssuranceInterview
                  }
                  row
                >
                  {yesNoOptions.map(({ title, value }, index) => {
                    const dataTestIds = [
                      ComponentTestIds.receivingCareAllowance
                        .qualityAssuranceSelection.yes,
                      ComponentTestIds.receivingCareAllowance
                        .qualityAssuranceSelection.no,
                    ];

                    return (
                      <FormControlLabel
                        key={`qualityAssuranceInterview-${title}`}
                        control={<Radio data-test-id={dataTestIds[index]} />}
                        label={
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {title}
                          </Box>
                        }
                        labelPlacement="end"
                        value={value}
                      />
                    );
                  })}
                </RadioGroup>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <FormControl
                  data-test-id={
                    ComponentTestIds.receivingCareAllowance.careService
                  }
                  fullWidth
                >
                  {/* Using Typography instad label property because of long texts. */}
                  <Typography>
                    {t(
                      'NeedsAssessmentForm.ReceivingCareAllowance.whichCareService',
                    )}{' '}
                    *
                  </Typography>
                  <TextField
                    error={
                      !!(
                        touched.receivingCareAllowance?.careService &&
                        errors.receivingCareAllowance?.careService
                      )
                    }
                    helperText={
                      touched.receivingCareAllowance?.careService &&
                      errors.receivingCareAllowance?.careService
                    }
                    name="receivingCareAllowance.careService"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    type="text"
                    value={values.receivingCareAllowance.careService}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            )}
            {values.receivingCareAllowance.value ==
              CareAllowanceOptions.YES && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  {values.receivingCareAllowance.qualityAssuranceInterview ===
                  'yes' ? (
                    <>
                      {/* Using Typography instad label property because of long texts. */}
                      <Typography>
                        {t(
                          'NeedsAssessmentForm.ReceivingCareAllowance.qualityAssurancePerson',
                        )}{' '}
                        *
                      </Typography>
                      <TextField
                        data-test-id={
                          ComponentTestIds.receivingCareAllowance
                            .qualityAssurancePerson
                        }
                        error={
                          !!(
                            touched.receivingCareAllowance
                              ?.qualityAssurancePerson &&
                            errors.receivingCareAllowance
                              ?.qualityAssurancePerson
                          )
                        }
                        helperText={
                          touched.receivingCareAllowance
                            ?.qualityAssurancePerson &&
                          errors.receivingCareAllowance?.qualityAssurancePerson
                        }
                        name="receivingCareAllowance.qualityAssurancePerson"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={
                          values.receivingCareAllowance.qualityAssurancePerson
                        }
                        variant="outlined"
                      />
                    </>
                  ) : (
                    <>
                      {/* Using Typography instad label property because of long texts. */}
                      <Typography>
                        {t(
                          'NeedsAssessmentForm.ReceivingCareAllowance.toWhichCareService',
                        )}{' '}
                        *
                      </Typography>
                      <TextField
                        data-test-id={
                          ComponentTestIds.receivingCareAllowance.careService
                        }
                        error={
                          !!(
                            touched.receivingCareAllowance?.careService &&
                            errors.receivingCareAllowance?.careService
                          )
                        }
                        helperText={
                          touched.receivingCareAllowance?.careService &&
                          errors.receivingCareAllowance?.careService
                        }
                        name="receivingCareAllowance.careService"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.receivingCareAllowance.careService}
                        variant="outlined"
                      />
                    </>
                  )}
                </FormControl>
              </Grid>
            )}

            <SectionTitle
              title={t('NeedsAssessmentForm.RequiredServices.title')}
            />
            <Grid item xs={12}>
              <FormGroup id="requiredServices">
                <Grid container>
                  {requiredServiceOptions({ ...values.requiredServices }).map(
                    ({ checked, dataTestId, label, name }) => (
                      <Grid key={name} item mb={2} xs={6}>
                        <FormControlLabel
                          name={name}
                          control={
                            <Checkbox
                              data-test-id={dataTestId}
                              color="primary"
                              checked={checked}
                            />
                          }
                          label={
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {label}
                            </Box>
                          }
                          labelPlacement="end"
                          onChange={handleChange}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
                {shouldValidate && errors.requiredServices?.bedLinen && (
                  <Box color={themeColors.error} display="flex">
                    <Error
                      style={{
                        fontSize: '1.125em',
                        marginRight: '0.5em',
                      }}
                    />
                    <Typography
                      data-test-id={ComponentTestIds.requiredServices.error}
                      fontSize="12px"
                      mt={0.15}
                    >
                      {errors.requiredServices?.bedLinen}
                    </Typography>
                  </Box>
                )}
              </FormGroup>
            </Grid>

            <SectionTitle
              title={t('NeedsAssessmentForm.DeploymentPlanning.title')}
            />
            <Subtitle
              title={t(
                'NeedsAssessmentForm.DeploymentPlanning.healthInsuranceBenefits',
              )}
            />
            <Grid item xs={12}>
              <RadioGroup
                data-test-id={ComponentTestIds.healthInsuranceBenefits}
                name="healthInsuranceBenefits.selection"
                onChange={handleChange}
                value={values.healthInsuranceBenefits.selection}
                row
              >
                <FormControlLabel
                  data-test-id={ComponentTestIds.EL_VP}
                  control={<Radio />}
                  label={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {t('NeedsAssessmentForm.DeploymentPlanning.EL_VP')}
                    </Box>
                  }
                  labelPlacement="end"
                  value="EL&VP"
                />

                <FormControlLabel
                  data-test-id={ComponentTestIds.withoutVP}
                  control={<Radio />}
                  label={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {t('NeedsAssessmentForm.DeploymentPlanning.withoutVP')}
                    </Box>
                  }
                  labelPlacement="end"
                  value="withoutVP"
                />
              </RadioGroup>
            </Grid>
            <Subtitle
              title={t(
                'NeedsAssessmentForm.DeploymentPlanning.deploymentPlanning',
              )}
            />
            <Grid item xs={12}>
              <FormControlLabel
                data-test-id={ComponentTestIds.deploymentPlanning.careAllowance}
                name="deploymentPlanning.careAllowance"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.deploymentPlanning.careAllowance}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        formikRef.current?.setFieldValue(
                          'deploymentPlanning.careAllowanceHours',
                          0,
                        );
                      }

                      handleChange(event);
                    }}
                  />
                }
                label={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {t('NeedsAssessmentForm.DeploymentPlanning.careAllowance')}
                  </Box>
                }
                labelPlacement="end"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                data-test-id={
                  ComponentTestIds.deploymentPlanning.privateAccount
                }
                name="deploymentPlanning.privateAccount"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.deploymentPlanning.privateAccount}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        formikRef.current?.setFieldValue(
                          'deploymentPlanning.privateAccountHours',
                          0,
                        );
                      }

                      handleChange(event);
                    }}
                  />
                }
                label={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {t('NeedsAssessmentForm.DeploymentPlanning.privateAccount')}
                  </Box>
                }
                labelPlacement="end"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Subtitle
                title={t('NeedsAssessmentForm.DeploymentPlanning.disposition')}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                data-test-id={
                  ComponentTestIds.deploymentPlanning.dispositionSelection
                }
                name="deploymentPlanning.dispositionSelection"
                onChange={handleChange}
                value={values.deploymentPlanning.dispositionSelection}
              >
                {dispositionOptions({
                  valueBiWeekly:
                    values.deploymentPlanning.dispositionHoursBiWeekly,
                  valueWeekly: values.deploymentPlanning.dispositionHoursWeekly,
                }).map((option) => (
                  <Fragment key={option.value}>
                    <FormControlLabel
                      data-test-id={option.dataTestId}
                      control={<Radio />}
                      label={
                        <>
                          <Box
                            data-test-id={`${TestIds.components.hourSelect}-${option.value}`}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <HourSelect
                              disabled={
                                option.value === 'weekly'
                                  ? values.deploymentPlanning
                                      .dispositionSelection === 'biWeekly'
                                  : values.deploymentPlanning
                                      .dispositionSelection === 'weekly'
                              }
                              handleOnChange={(event) => {
                                formikRef.current?.setFieldValue(
                                  option.fieldName,
                                  event.target.value,
                                );

                                handleChange(event);
                              }}
                              value={option.selectValue}
                            />
                            <Typography ml={1}>{option.label}</Typography>
                          </Box>
                        </>
                      }
                      labelPlacement="end"
                      value={option.value}
                    />
                    <HourSelectError
                      type={option.value}
                      errors={
                        shouldValidate
                          ? {
                              biWeekly:
                                errors.deploymentPlanning
                                  ?.dispositionHoursBiWeekly,
                              weekly:
                                errors.deploymentPlanning
                                  ?.dispositionHoursWeekly,
                            }
                          : undefined
                      }
                    />
                  </Fragment>
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <span style={{ fontWeight: 500 }}>
                  {t('NeedsAssessmentForm.Specifics.title')}{' '}
                </span>
                <span>{t('NeedsAssessmentForm.Specifics.optional')}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {t('NeedsAssessmentForm.Specifics.smoker')}
              </Typography>
              <RadioGroup
                data-test-id={ComponentTestIds.specifics.smoker}
                name="specifics.smoker"
                onChange={handleChange}
                value={values.specifics?.smoker}
                row
              >
                {yesNoOptions.map(({ title, value }) => (
                  <FormControlLabel
                    key={`smoker-${title}`}
                    control={<Radio />}
                    label={
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {title}
                      </Box>
                    }
                    labelPlacement="end"
                    value={value}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                data-test-id={ComponentTestIds.specifics.animals}
                fullWidth
              >
                <TextField
                  label={t('NeedsAssessmentForm.Specifics.animals')}
                  name="specifics.animals"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.specifics?.animals}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                data-test-id={ComponentTestIds.specifics.others}
                fullWidth
              >
                <TextField
                  label={t('NeedsAssessmentForm.Specifics.others')}
                  name="specifics.others"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.specifics?.others}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Subtitle
              title={t('NeedsAssessmentForm.DeploymentPlanning.start')}
            />
            <Grid item xs={12}>
              <DatePicker
                closeOnSelect
                inputFormat="dd | MM | yyyy"
                renderInput={(props) => (
                  <TextField
                    data-test-id={ComponentTestIds.startDate}
                    variant="outlined"
                    name="startDate"
                    required
                    fullWidth
                    {...props}
                  />
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon />
                    </InputAdornment>
                  ),
                }}
                label={t('General.Form.dateLabel')}
                onChange={(value) =>
                  formikRef.current?.setFieldValue(
                    'startDate',
                    format(new Date(value || ''), 'yyyy-MM-dd'),
                  )
                }
                value={values.startDate}
              />
            </Grid>

            <SectionTitle
              title={t('NeedsAssessmentForm.FreeOfChargeAid.title')}
            />
            <Grid item xs={12}>
              <FormControlLabel
                data-test-id={ComponentTestIds.freeOfChargeAid.careBox}
                name="freeOfChargeAidRequested.shouldOrderCareBox"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.freeOfChargeAidRequested.shouldOrderCareBox}
                    disabled={!customer?.canOrderCareBox}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                }
                label={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {t('NeedsAssessmentForm.FreeOfChargeAid.careBox')}
                    {!customer?.canOrderCareBox && (
                      <IconButton
                        children={<Info />}
                        color="warning"
                        onClick={() => setShowInfoText(!showInfoText)}
                      />
                    )}
                  </Box>
                }
                labelPlacement="end"
                onChange={handleChange}
              />
              {showInfoText && (
                <Box mt={3}>
                  <Alert severity="info">
                    {t('NeedsAssessmentForm.FreeOfChargeAid.infoTextCareBox')}
                  </Alert>
                </Box>
              )}
            </Grid>
            {freeOfChargeAid({
              ...values.freeOfChargeAidRequested,
            }).map(({ checked, dataTestId, label, name }) => (
              <Grid key={name} item xs={12}>
                <FormControlLabel
                  data-test-id={dataTestId}
                  name={name}
                  control={<Checkbox color="primary" checked={checked} />}
                  label={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {label}
                    </Box>
                  }
                  labelPlacement="end"
                  onChange={handleChange}
                />
              </Grid>
            ))}
            <Grid item mt={4} xs={12}>
              <FormControl
                data-test-id={ComponentTestIds.signatureCustomer}
                error={
                  !!(errors.signatureCustomer && touched.signatureCustomer)
                }
                fullWidth
              >
                {!values.signatureCustomer && (
                  <FormLabel
                    className={signatureFieldLabel}
                    onClick={() => setSignatureOpen(true)}
                  >
                    <Add />
                    {t('NeedsAssessmentForm.Signature.label')}
                  </FormLabel>
                )}

                {values.signatureCustomer && (
                  <Box mb={2} className={signaturePreview}>
                    <Image src={values.signatureCustomer} />
                    <Typography className={signatureName}>
                      {t('NeedsAssessmentForm.Signature.helperText')}
                    </Typography>
                    <Edit
                      className={editIcon}
                      onClick={() => setSignatureOpen(true)}
                    />
                  </Box>
                )}

                {errors.signatureCustomer && touched.signatureCustomer && (
                  <FormHelperText>
                    <Error /> {errors.signatureCustomer}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" mb={4} mt={4}>
            <Button
              data-test-id={ComponentTestIds.submitButton}
              color="secondary"
              disabled={isSubmitting}
              loading={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setShouldValidate(true);
                scrollToError(errors);
              }}
            >
              {t('NeedsAssessmentForm.submit')}
            </Button>
          </Box>
          <Signature
            signatory="signatureNeedsAssessmentCustomer"
            open={signatureOpen}
            onClose={() => setSignatureOpen(false)}
            onSuccess={(signature) => {
              setFieldValue('signatureCustomer', signature);
              setSignatureOpen(false);
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
